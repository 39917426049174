import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from '../../../utils/apiUrl';

export const registerUser = createAsyncThunk<any, any>(
  "user/registerUser",
  async (data: any, thunkApi) => {
    try {
      data?.setLoading(true);
      const response = await axios.post(
        `${apiUrl}/api/v1/register`,
        data.values,
        { headers: { "Content-Type": "application/json" } }
      );
      data?.setLoading(false);
      return thunkApi.fulfillWithValue(response.data);
    } catch (error: any) {
      data?.setLoading(false);
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
