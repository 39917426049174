import React, { useEffect, useRef, useState } from "react";
import { PDFDocumentProxy } from "pdfjs-dist";
import { DrawCall, MouseEvent } from "./pdfeditor";
import { MouseEventType } from "./pdfeditor.enum";
export interface RendererProps {
  pdf: PDFDocumentProxy | undefined;
  drawCalls: { [page: number]: DrawCall[] };
  zoom: number;
  page: number;
  eventHandler?: (mouseEvent: MouseEvent) => void;
}

const Renderer: React.FC<RendererProps> = ({
  pdf,
  zoom,
  page,
  drawCalls,
  eventHandler,
}: RendererProps) => {
  const drawing = useRef<boolean>(false);
  const canvas = useRef<HTMLCanvasElement | null>(null);
  const [canvasDimensions, setCanvasDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  const render = async () => {
    try {
      if (!drawing.current && pdf && canvas.current) {
        drawing.current = true;
        const ctx = canvas.current.getContext("2d");
        if (!ctx) throw new Error("CTX could not be loaded");

        ctx.clearRect(200, 0, ctx.canvas.width, ctx.canvas.height);

        const pageObject = await pdf.getPage(page);

        const viewport = pageObject.getViewport({
          scale: zoom,
        });

        setCanvasDimensions({
          width: viewport.width,
          height: viewport.height,
        });

        await pageObject.render({
          canvasContext: ctx,
          viewport: pageObject.getViewport({ scale: zoom }),
        }).promise;

        drawCalls[page]?.forEach((call) => {
          switch (call.type) {
            case "rect":
              ctx.strokeStyle = "#1C4680" || call.data || "#1C4680";
              ctx.strokeRect(
                call.position.x * zoom,
                call.position.y * zoom,
                call.size.width * zoom,
                call.size.height * zoom
              );
              break;
            case "text":
              ctx.font = 12 * zoom + "px Arial";
              ctx.fillStyle = "blue";

              ctx.fillText(
                call.data,
                call.position.x * zoom,
                call.position.y * zoom
              );
              break;
            case "image":
              ctx.drawImage(
                call.data,
                call.position.x,
                call.position.y,
                call.size.width,
                call.size.height
              );
              break;
          }
        });

        drawing.current = false;
      }
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    render();
    // eslint-disable-next-line
  }, [canvas.current, drawCalls, zoom, page]);

  const emitEvent = (
    e: React.MouseEvent<HTMLCanvasElement, globalThis.MouseEvent> , 
    mouseEventType: MouseEventType
  ) => {
    if (eventHandler) {
      const newMouseEvent: MouseEvent = {
        pos: {
          x: e.nativeEvent.offsetX / zoom,
          y: e.nativeEvent.offsetY / zoom,
        },
        type: mouseEventType,
        page: page,
      };
      
      eventHandler(newMouseEvent);
    }
  };

  const emitEventonTouch = (
  e: React.TouchEvent<HTMLCanvasElement>,
  mouseEventType: MouseEventType
) => {
  if (eventHandler && canvas.current) {
    const rect = canvas.current.getBoundingClientRect();
    if (e.touches.length > 0) {
      const touch = e.touches[0];
      const newMouseEvent: MouseEvent = {
        pos: {
          x: Math.round((touch.clientX - rect.left) / zoom),
          y: Math.round((touch.clientY - rect.top) / zoom),
        },
        type: mouseEventType,
        page: page,
      };
      
      eventHandler(newMouseEvent);
    }
  }
};


  return (
    <div className="canvas-container">
      <canvas
        ref={canvas}
        width={canvasDimensions.width}
        height={canvasDimensions.height}
        onMouseDownCapture={(e) => emitEvent(e, MouseEventType.MOUSE_DOWN)}
        onMouseUpCapture={(e) => emitEvent(e, MouseEventType.MOUSE_UP)}
        onMouseMoveCapture={(e) => emitEvent(e, MouseEventType.MOUSE_MOVE)}
        onMouseOutCapture={(e) => emitEvent(e, MouseEventType.MOUSE_LEAVE)}
        onTouchStartCapture={(e) => emitEventonTouch(e, MouseEventType.TOUCH_START)}
        onTouchEndCapture={(e) => emitEventonTouch(e, MouseEventType.TOUCH_END)}
        onTouchMoveCapture={(e) => emitEventonTouch(e, MouseEventType.TOUCH_MOVE)}
        onTouchCancelCapture={(e) => emitEventonTouch(e, MouseEventType.TOUCH_CANCEL)}
      />
    </div>
  );
};

export { Renderer };
