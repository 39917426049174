/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAssignedUser } from "../../apiServices/addpdf/index";
import { logOutToken } from "slices/auth/login/reducer";
import PagingControl from "../../Components/PagingControl";
import { PDFDocument, rgb } from "pdf-lib";
import { blobToURL } from "pages/Utility/Utils";
import fontkit from "@pdf-lib/fontkit";
import { apiUrlnoport } from '../../utils/apiUrl';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const styles = {
  container: {
    maxWidth: 900,
    margin: "0 auto",
  },
  sigBlock: {
    display: "inline-block",
    border: "1px solid #000",
  },
  documentBlock: {
    maxWidth: 800,
    margin: "20px auto",
    marginTop: 8,
  },
  controls: {
    maxWidth: 800,
    margin: "0 auto",
    marginTop: 8,
  },
};

const Index = () => {
  const [totalPages, setTotalPages] = useState(0);
  const [pageNum, setPageNum] = useState(0);
  const [pageDetails, setPageDetails] = useState(null);
  const [pdfInfo, setPdf] = useState<any>();
  const [pdf, setPdfData] = useState("");
  const { id }: any = useParams();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const [pdfData, setPdfDataInfo] = useState("");
  const [stateData, setStateData] = useState(false);
  const { accessToken, loading } = useSelector((store: any) => {
    return store.Login;
  });
  const logoutFunction = () => {
    dispatch(logOutToken(accessToken));
  };
  useEffect(() => {
    getAssignedUser(
      accessToken,
      id,
      setPdf,
      setLoader,
      logoutFunction,
      setPdfData,
      setStateData
    ); // eslint-disable-next-line
  }, []);

  const pdfDataRef: any = useRef(null); // Create a ref for the target element with id "pdfdata"

  const getData = async (uint8Array: any) => {
    const pdfDoc = await PDFDocument.load(uint8Array, {
      ignoreEncryption: true,
    });

    const pages = pdfDoc.getPages();
    pdfDoc.registerFontkit(fontkit);

    const urldata = `${apiUrlnoport}/HelveticaWorld-Regular.ttf`;
    const fontBytes = await fetch(urldata).then((res) => res.arrayBuffer());

    const customFont = await pdfDoc.embedFont(fontBytes, {
      subset: true,
    });
    for (let i = 0; i < pdfInfo?.get_user_ordered?.length; i++) {
      if (
        pdfInfo?.get_user_ordered[i]?.status === false ||
        pdfInfo?.get_user_ordered[i]?.status === 0
      ) {
        const x = pdfInfo.box_position[i]["x"];
        const y =
          pages[pdfInfo?.page_index[i] - 1]?.getSize().height -
          pdfInfo.box_position[i]["y"] -
          25;

        const width = pdfInfo.box_size[i]["width"];
        const height = pdfInfo.box_size[i]["height"];

        // page_index
        pages[pdfInfo?.page_index[i] - 1]?.drawRectangle({
          x,
          y,
          width,
          height,
          borderColor: rgb(0.1098, 0.2745, 0.5019),
          borderWidth: 2,
          color: rgb(0.9, 0.9, 0.9),
        });

        var text;
        if (pdfInfo?.signature_type[i] === "initials") {
          text = "التأشير";
        } else {
          text = pdfInfo?.get_user_ordered[i].user_name;
        }

        const fontSize = 8;
        const textColor = rgb(0, 0, 0.9); // Black color

        // Calculate the position to center the text within the box
        const textX = x + width / 2 - (text?.length * fontSize) / 4;
        const textY = y + height / 2 - fontSize / 2;

        // Draw the text on the page
        pages[pdfInfo?.page_index[i] - 1]?.drawText(text, {
          x: textX,
          y: textY,
          color: textColor,
          size: 10,
          font: customFont,
        });
      }
    }

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([new Uint8Array(pdfBytes)]);

    const URL: any = await blobToURL(blob);

    setPdfData(URL);
  };
  const base64ToUint8Array = (base64WithoutPrefix) => {
    const binaryString = atob(base64WithoutPrefix);

    const length = binaryString.length;
    const uint8Array: any = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    getData(uint8Array);
  };
  useEffect(() => {
    if (stateData === true) {
      const base64WithoutPrefix: any = pdf.replace(
        /^data:application\/pdf;base64,/,
        ""
      );

      base64ToUint8Array(base64WithoutPrefix);

      // getData();
    }
    // eslint-disable-next-line
  }, [stateData]);
  return (
    <div style={styles.documentBlock}>
      {pdf && (
        <>
          <div id="pdfdata" ref={pdfDataRef}>
            <Document
              file={pdf}
              onLoadSuccess={(data) => {
                setTotalPages(data.numPages);
              }}
            >
              <Page
                pageNumber={pageNum + 1}
                width={800}
                height={1200}
                onLoadSuccess={(data: any) => {
                  setPageDetails(data);
                }}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              ></Page>
            </Document>
            <PagingControl
              pageNum={pageNum}
              setPageNum={setPageNum}
              totalPages={totalPages}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Index;
