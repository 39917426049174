import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "Components/Common/withRouter";
import { logOutToken } from "slices/auth/login/reducer";
import "flatpickr/dist/themes/material_blue.css";
import Select from "react-select";
import { formatDate } from "../../common/datecommonfunction";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Button,
  Card,
  CardBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { apiUrl } from '../../utils/apiUrl';
//Import Component
import Breadcrumbs from "Components/Common/Breadcrumb";
import DeleteModal from "Components/Common/DeleteModal";
import {
  getAllPdfFiles,
  getAllUsers,
  addPdfAssign,
  getAllAssignPdf,
  deleteAssignedUser,
  addRemainder,
} from "../../apiServices/assignpdf/index";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";
import { ToastContainer, toast } from "react-toastify";


const MultiSelectcustomStyles = {
  input: (provided: any, state: any) => ({
    ...provided,
    color: "#fff",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "red" : "#fff",
    backgroundColor: state.isSelected ? "#006cf9" : "#0c2144",
    "&:hover": {
      backgroundColor: "#006cf9",
    },
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    color: "#fff",
    backgroundColor: "#0c2144",
    borderColor: "rgba(60 ,86 ,124, 50%)",
    "&:hover": {
      borderColor: "rgba(60 ,86 ,124, 50%)",
    },
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: "#fff",
    // color: state.isFocused
    //   ? "rgba(209, 228, 255, 0.5)"
    //   : "rgba(209, 228, 255, 0.5)",
  }),
};
const ProjectsList = () => {
  //meta title
  document.title = "Signready ";

  const dispatch = useDispatch<any>();
  const { accessToken: tokenget } = useSelector((store: any) => store.Login);
  const { isAdmin } = useSelector((store: any) => store?.Login?.user);
  const navigate = useNavigate();
  const [project, setProject] = useState<any>([]);
  const [userList, setUserList] = useState<any>([]);
  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {},
    validationSchema: Yup.object({}),
    onSubmit: (values: any) => {
      if (project.length !== 0 && userList.length !== 0) {
        addPdfAssign(
          accessToken,
          logoutFunction,
          toggle,
          setLoading,
          project,
          userList,
          setProject,
          setUserList,
          setProjectData,
          isAdmin,
          dispatch
        );
      } else {
        toast.error("الرجاء تعبئة الحقول كاملة");
        // toast.error("please fill the fields");
      }
    },
  });

  const [isLoading, setLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [allpdfDocument, setAllPdfDocument] = useState([]);
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setProject(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleProjectClick = useCallback(
    (arg: any) => {
      const project = arg;

      setIsEdit(true);
      navigate(`/viewDocument/${project.id}`);
      // eslint-disable-next-line
    },
    // eslint-disable-next-line
    [toggle]
  );

  // arr = [1, 2, 3, 4, 1, 5];
  // k = 3;
  // length = 2;
  // let arr2 = [];
  // for (let i = 0; i < arr.length; i++) {
  //   for (let j = i; j < arr.length; j++) {
  //     for (let k = j; k < j; k++) {

  //     }
  //   }
  // }

  //delete order
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const onClickDelete = useCallback((project: any) => {
    setDeleteProject(project);
    setDeleteModal(true);
  }, []);

  const handleDeleteOrder = () => {
    if (deleteProjectData && deleteProjectData.id) {
      deleteAssignedUser(
        accessToken,
        deleteProjectData.id,
        setProjectData,
        logoutFunction,
        isAdmin,
        setLoading,
        setDeleteModal,
        dispatch
      );
    }
  };

  const [projectData, setProjectData] = useState<any>([]);
  const [deleteProjectData, setDeleteProject] = useState<any>();
  const { accessToken } = useSelector((store: any) => {
    return store.Login;
  });
  const { id } = useSelector((store: any) => store?.Login?.user);
  const [users, setUsers] = useState<any>([]);

  const handleProjectClicks = () => {
    setIsEdit(false);
    setProject([]);
    setUserList([]);
    toggle();
    navigate(`/assign-document`);
  };
  const handleProjectClickspasscode = () => {
    setIsEdit(false);
    setProject([]);
    setUserList([]);
    toggle();
    navigate(`/assign-document-passcode`);
  };
  const handleViewuser = useCallback(
    (arg: any) => {
      const project = arg;

      // viewPdf(
      //   accessToken,
      //   logoutFunction,
      //   setLoading,
      //   project.id,
      //   setProject,
      //   setUserList
      // );

      setIsEdit(true);
      // const data = CryptoJS.AES.encrypt(
      //   JSON.stringify(project.id),
      //   process.env.REACT_APP_SECRETID
      // ).toString();

      // // toggle();
      navigate(`/view-document/${project.id}?id=${project.document_id}`);
      // eslint-disable-next-line
    },
    // eslint-disable-next-line
    []
  );

  const fetchDocumentName = async (filePathUrl) => {
    try {
      const response = await axios.get(`${apiUrl}/api/v1/get-document-name`, {
        params: {
          file_path_url: filePathUrl,
        },
        headers: {
          Authorization: tokenget,
          "Content-Type": "application/json",
        },
      });
  
      const data = response.data;
  
      if (data && data.message === 'fetch Successfully') {
        return data.data; // Return the processed data
      } else {
        console.error('Unexpected response structure:', data);
        return null;
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      return null;
    }
  };
  
  

const handleDownload = async (downloadData) => {
  try {

    const filePathUrl = downloadData?.get_document[0].file_path;
    const documentInfo:any = await fetchDocumentName(filePathUrl);

    if (documentInfo) {
      const fileData = documentInfo;
      const link = document.createElement("a");
      link.download = "file-signready.pdf";
      link.href = fileData;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Unexpected document info structure:', documentInfo);
    }
  } catch (error) {
    console.error('There was a problem with the download operation:', error);
  }
};

  const handleRemainder = (remainderData: any) => {
    addRemainder(accessToken, setLoading, logoutFunction, remainderData?.id);
  };
  
  const columns = useMemo(() => {
    return isAdmin === 1 || isAdmin === true
      ? [
          {
            header: "#",
            id: "#",

            enableColumnFilter: false,
            enableSorting: true,
          },
          {
            // header: "Title",
            header: "العنوان",
            accessorKey: "get_document",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cellProps: any) => {
              return (
                
                <>
                {console.log(cellProps)} {/* Logs the entire cellProps object */}
                  {cellProps.row.original.get_document.map(
                    
                    (member: any, index: number) => (
                      
                      <h5
                        className="text-truncate font-size-14 cursor-pointer"
                        key={index}
                        onClick={() => {
                          handleProjectClick(cellProps.row.original);
                        }}
                      >
                        {/* <Link
                          to={member.file_path_url}
                          target="_blank"
                          className="text-dark cursor-pointer"
                        > */}
                        {member?.title?.replace(/\.pdf$/, "")}
                      </h5>
                    )
                  )}
                </>
              );
            },
          },
          {
            // header: "Due Date",
            header: "تاريخ الاستحقاق",
            accessorKey: "created_at",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cellProps: any) => {
              return (
                <>
                  <p className="mb-0">
                    {formatDate(cellProps.row.original.created_at)}
                  </p>
                </>
              );
            },
          },
          {
            // header: "Status",
            header: "الحالة ",
            accessorKey: "status",
            cell: (cellProps: any) => {
              return cellProps.row.original.get_user_ordered?.every(
                (order: any) => order.status === 1 || order.status === true
              ) ? (
                <div>
                  <p className="mb-0 btn-success bg-txt px-2 ">
                    {/* Completed */}
                    مكتمل
                  </p>
                </div>
              ) : (
                <p className="mb-0 px-2 bg-warning bg-txt bg-secondary">
                  {/* Progress */}
                  قيد الانتظار
                </p>
              );
              // <ProjectStatus status={cellProps.row.original.status} />
            },
            enableColumnFilter: false,
            enableSorting: true,
          },

          {
            // header: "Assign Users",
            header: "تعيين مستخدمين ",
            accessorKey: "get_user_ordered",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cellProps: any) => {
              const filteredData =
                cellProps.row.original.get_user_ordered.filter(
                  (value: any, index: any, self: any) =>
                    self.findIndex((v: any) => v.user_id === value.user_id) ===
                    index
                );

              return (
                <div className="avatar-group">
                  {filteredData &&
                    filteredData?.map((member: any, index: number) => (
                      <div className="avatar-group-item" key={index}>
                        {/* <UncontrolledTooltip
                        placement="right"
                        target={`UncontrolledTooltipExample-${member.id}`}
                      >
                        {member.name}
                      </UncontrolledTooltip> */}
                        <Link
                          to="#"
                          className="d-inline-block"
                          id={`UncontrolledTooltipExample-${member.id}`}
                        >
                          <div className="avatar-xs">
                            <span
                              className={`avatar-group-datatitle rounded-circle bg-${member.color}  text-white font-size-16`}
                            >
                              {member.user_name
                                ? member.user_name.charAt(0)
                                : ""}
                            </span>
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
              );
            },
          },

          {
            // header: "Action",
            header: "الإعدادات",
            accessorKey: "action",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cellProps: any) => {
              return (
                <UncontrolledDropdown>
                  <DropdownToggle tag="a" className="card-drop">
                    <i className="mdi mdi-dots-horizontal font-size-18" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      onClick={() => handleProjectClick(cellProps.row.original)}
                    >
                      <i
                        // mdi-eye
                        className="mdi mdi-eye font-size-16 text-black me-1"
                        id="viewtooltip"
                      ></i>
                      {/* View */}
                      عرض
                    </DropdownItem>
                    {/* Conditionally render the Delete action */}
                  
                    <DropdownItem
                      onClick={() => onClickDelete(cellProps.row.original)}
                    >
                      <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />
                      {/* Delete */}
                      حذف
                    </DropdownItem>
                  
                    {cellProps.row.original.get_user_ordered?.every(
                      (order: any) =>
                        order.status === 1 || order.status === true
                    ) ? (
                      <DropdownItem
                        onClick={() => handleDownload(cellProps.row.original)}
                      >
                        <i
                          // mdi-eye
                          className="mdi mdi-download font-size-16 text-black me-1"
                          id="viewtooltip"
                        ></i>
                        {/* Download */}
                        تحميل
                      </DropdownItem>
                    ) : (
                      <DropdownItem
                        onClick={() => handleRemainder(cellProps.row.original)}
                      >
                        <i
                          // mdi-eye
                          className="mdi mdi-email font-size-16 text-black me-1"
                          id="viewtooltip"
                        ></i>
                        {/* Send Remainder */}
                        إرسال تذكير
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              );
            },
          },
        ]
      : [
          {
            header: "#",
            id: "#",
            // cell: (cellProps: any) => (
            //   <div className="avatar-sm bg-light rounded p-2">
            //     {/* <img
            //       src={cellProps.row.original.img}
            //       alt=""
            //       className="img-fluid rounded-circle"
            //     /> */}
            //   </div>
            // ),
            enableColumnFilter: false,
            enableSorting: true,
          },
          {
            // header: "Title",
            header: "العنوان",
            accessorKey: "get_document",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cellProps: any) => {
              return (
                <>
                  {cellProps.row.original.get_document.map(
                    (member: any, index: number) => (
                      <h5
                        className="text-truncate font-size-14 cursor-pointer"
                        key={index}
                        onClick={() => {
                          handleViewuser(cellProps.row.original);
                        }}
                      >
                        {/* <Link
                          to={member.file_path_url}
                          target="_blank"
                          className="text-dark cursor-pointer"
                        > */}
                        {member?.title?.replace(/\.pdf$/, "")}
                      </h5>
                    )
                  )}
                </>
              );
            },
          },
          {
            // header: "Due Date",
            header: "تاريخ الاستحقاق",
            accessorKey: "created_at",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cellProps: any) => {
              return (
                <>
                  <p className="mb-0">
                    {formatDate(cellProps.row.original.created_at)}
                  </p>
                </>
              );
            },
          },
          {
            // header: "Status",
            header: "الحالة",
            accessorKey: "status",
            cell: (cellProps: any) => {
              // Check if the current user is the creator and all orders are incomplete
              if (
                cellProps.row.original.created_by_id === id &&
                cellProps.row.original.get_user_ordered?.every(
                  (order: any) => order.status === 0 || order.status === false
                )
              ) {
                return (
                  <div>
                    <p className="mb-0 btn-danger bg-txt px-2">
                      {/* Waiting for Signature */}
                      بانتظار التوقيع
                    </p>
                  </div>
                );
              }
            
              // Check if the current user has completed any order
              if (
                cellProps.row.original.get_user_ordered?.some(
                  (order: any) =>
                    (order.status === 1 || order.status === true)
                )
              ) {
                return (
                  <div>
                    <p className="mb-0 btn-success bg-txt px-2">
                      {/* Completed */}
                      مكتمل
                    </p>
                  </div>
                );
              }
            
              // Default case: Incomplete
              return (
                <div>
                  <p className="mb-0 bg-danger bg-txt px-2 bg-secondary">
                    {/* Incomplete */}
                    غير مكتمل
                  </p>
                </div>
              );
            
              // <ProjectStatus status={cellProps.row.original.status} />
            },
            enableColumnFilter: false,
            enableSorting: true,
          },
          {
            
            // header: "Action",
            header: "الإعدادات",
            accessorKey: "action",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cellProps: any) => {
              return (
                <UncontrolledDropdown>
                  <DropdownToggle tag="a" className="card-drop">
                    <i className="mdi mdi-dots-horizontal font-size-18" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      onClick={() => handleProjectClick(cellProps.row.original)}
                    >
                      <i
                        // mdi-eye
                        className="mdi mdi-eye font-size-16 text-black me-1"
                        id="viewtooltip"
                      ></i>
                      {/* View */}
                      عرض
                    </DropdownItem>
                    {cellProps.row.original.created_by_id === id && (
                    <DropdownItem
                      onClick={() => onClickDelete(cellProps.row.original)}
                    >
                      <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />
                      {/* Delete */}
                      حذف
                    </DropdownItem>
                    )}
                    {cellProps.row.original.get_user_ordered?.every(
                      (order: any) =>
                        order.status === 1 || order.status === true
                    ) ? (
                      <DropdownItem
                        onClick={() => handleDownload(cellProps.row.original)}
                      >
                        <i
                          // mdi-eye
                          className="mdi mdi-download font-size-16 text-black me-1"
                          id="viewtooltip"
                        ></i>
                        {/* Download */}
                        تحميل
                      </DropdownItem>
                    ) : (
                      cellProps.row.original.created_by_id === id && (
                      <DropdownItem
                      
                        onClick={() => handleRemainder(cellProps.row.original)}
                      >
                        <i
                          // mdi-eye
                          className="mdi mdi-email font-size-16 text-black me-1"
                          id="viewtooltip"
                        ></i>
                        {/* Send Remainder */}
                        إرسال تذكير
                      </DropdownItem>
                      )
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              );
            },
          },
        ];
        
    // eslint-disable-next-line
  }, [handleProjectClick, onClickDelete, isAdmin]);

  const logoutFunction = () => {
    dispatch(logOutToken(accessToken));
  };
  useEffect(() => {
    getAllAssignPdf(
      accessToken,
      setProjectData,
      logoutFunction,
      isAdmin,
      setLoading,
      dispatch
    );
    getAllPdfFiles(accessToken, setAllPdfDocument, logoutFunction, setLoading);
    getAllUsers(accessToken, setUsers, logoutFunction, setLoading);
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            // title="Assigned"
            title="التعيين"
            breadcrumbItem="قائمة الملفات الموقعة"
            // title="Assigned" breadcrumbItem="Assigned List"
          />
          <Row>
            <Col lg={12}>
              <div>
                {isLoading === true ? (
                  <div className="loader-box">
                    <div className="loader"></div>
                  </div>
                ) : (
                  ""
                )}
                {/* {isLoading ? (
                  <Spinners setLoading={setLoading} />
                ) : ( */}
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardBody>
                        <TableContainer
                          columns={columns}
                          data={projectData}
                          // isGlobalFilter={true}
                          isAddButton={
                            isAdmin === 1 || 1 === 1 ? true : false
                          }
                          isPagination={true}
                          isCustomPageSize={true}
                          handleUserClick={handleProjectClicks}
                          SearchPlaceholder="9 records..."
                          buttonClass="btn btn-success btn-rounded"
                          // buttonName=" Assign New Document"
                          buttonName="تعيين ملف جديد"
                          //passcodeButtonClass="btn btn-info btn-rounded"
                          //passcodeButtonName="تعيين غير مستخدم"
                          //handlepasscodeButtonClick={handleProjectClickspasscode}
                          tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                          theadClass="table-light"
                          paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                          pagination="pagination"
                          stateData={true}
                        />
                        
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Modal isOpen={modal} toggle={toggle}>
                  <ModalHeader toggle={toggle} tag="h4">
                    {/* {!!isEdit ? "View Assign Users" : "Assign Pdf"} */}
                    {!!isEdit ? "عرض الموقعين" : "تعيين مستخدمين للتوقيع"}
                  </ModalHeader>
                  <ModalBody>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                      autoComplete="off"
                    >
                      <Row>
                        <Col xs={12}>
                          <div className="text-center"></div>
                          <div className="mb-3">
                            <Label>
                              {/* Pdf */}
                              ملف
                            </Label>
                            <Select
                              styles={MultiSelectcustomStyles}
                              options={allpdfDocument}
                              value={project}
                              onChange={(e: any) => {
                                setProject({
                                  value: e.value,
                                  label: e.label,
                                });
                              }}
                              isDisabled={isEdit ? true : false}
                            />
                          </div>

                          <div className="mb-3">
                            <Label>
                              تعيين مستخدمين
                              {/* Assign Users */}
                            </Label>
                            <Select
                              styles={MultiSelectcustomStyles}
                              options={users}
                              value={userList}
                              onChange={(e) => {
                                let dataId = e.map((data: any) => {
                                  return {
                                    value: data.value,
                                    label: data.label,
                                  };
                                });
                                setUserList(dataId);
                              }}
                              isDisabled={isEdit ? true : false}
                              isMulti
                            />
                          </div>
                        </Col>
                      </Row>
                      {/* // */}
                      <Row>
                        {isEdit === false && (
                          <Col>
                            <div className="text-end">
                              <Button
                                type="submit"
                                color="success"
                                className="save-user"
                              >
                                {/* Save */}
                                حفظ
                              </Button>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ProjectsList);
