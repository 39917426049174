import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { logOutToken } from './reducer'
import { apiUrl } from '../../../utils/apiUrl';

export type ResetPasswordDto = {
  code: string
  password: string
  password_confirmation: string
}

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (data: any, thunkApi) => {
    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/login`,
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )

      toast.success('User login Successful')
      return thunkApi.fulfillWithValue(response.data)
    } catch (error: any) {
      toast.error(
        error.response ? error.response.data.message : 'حدث خطأ ما'
      )
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const logoutUser = createAsyncThunk(
  'user/logoutUser',
  async (data: any, thunkApi) => {
    try {
      // const response = await axios.post(
      //   `${apiUrl}/api/v1/logout`,
      //   {},
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: data,
      //     },
      //   }
      // );
      const dispatch = useDispatch()
      dispatch(logOutToken({}))
      toast.success('Logged out successfully')
      // return thunkApi.fulfillWithValue(response.data);
    } catch (error: any) {
      // // console.log("error", error);
      // toast.error(
      //   error.response ? error.response.data.message : "حدث خطأ ما"
      // );
      // return thunkApi.rejectWithValue(error.response.data);
    }
  }
)

export const forgotPassword = createAsyncThunk(
  'user/forgotPassword',
  async (data: any, thunkApi) => {
    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/forgot-password`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      toast.success('Please check your mail for OTP')
      return thunkApi.fulfillWithValue(response.data)
    } catch (error: any) {
      toast.error(
        error.response ? error.response.data.message : 'حدث خطأ ما'
      )
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async (data: ResetPasswordDto, thunkApi) => {
    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/reset-password`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      toast.success('Password reset successfully')
      return thunkApi.fulfillWithValue(response.data)
    } catch (error: any) {
      toast.error(
        error.response ? error.response.data.message : 'حدث خطأ ما'
      )
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const changePassword = createAsyncThunk(
  'user/changePassword',
  async (
    data: {
      password: string
      password_confirmation: string
      accessToken: string
    },
    thunkApi
  ) => {
    const { password, password_confirmation, accessToken } = data
    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/change-password`,
        { password, password_confirmation },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: accessToken,
          },
        }
      )
      toast.success('Password changed successfully')
      return thunkApi.fulfillWithValue(response.data)
    } catch (error: any) {
      toast.error('حدث خطأ ما')
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
