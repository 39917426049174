import axios from "axios";
import { toast } from "react-toastify";
import { logoutUser } from "slices/thunk";
import { apiUrl } from '../../utils/apiUrl';
export const createFolder = async (
  body: object,
  accessToken: string,
  setLoading: any,
  setOpenModal: any,
  getFileFolders
) => {
  try {
    setLoading(true);
    await axios.post(
      `${apiUrl}/api/v1/folders/created`,
      body,
      { headers: { Authorization: accessToken } }
    );
    getFileFolders();
    toast.success("Folder created successfully");
    setOpenModal(false);
    setLoading(false);
  } catch (error: any) {
    setLoading(false);
    if (error?.response?.data?.status === 401) {
      logoutUser({});
      return;
    }

    toast.error(
      error?.response?.data?.message
        ? error?.response?.data?.message
        : "حدث خطأ ما"
    );
    setOpenModal(false);
  }
};

export const createFile = async (
  body: any,
  accessToken: string,
  setLoading: any,
  setOpenModal: any,
  getFileFolders: any,
  fetchRecentFiles: any
) => {
  try {
    setLoading(true);
    await axios.post(
      `${apiUrl}/api/v1/file/uploaded`,
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: accessToken,
        },
      }
    );

    getFileFolders();
    // toast.success('File created successfully')
    toast.success("تم إنشاء الملف بنجاح");
    setOpenModal(false);
    setLoading(false);
    fetchRecentFiles();
  } catch (error: any) {
    setLoading(false);
    if (error?.response?.data?.status === 401) {
      logoutUser({});
      return;
    }

    if (error?.response?.data?.error?.pdf) {
      toast.error(error.response.data.error.pdf[0]);
      return;
    }
    toast.error(
      error?.response?.data?.message
        ? error?.response?.data?.message
        : "حدث خطأ ما"
    );
  }
};

export const renameFolder = async (
  body: object,
  accessToken: string,
  getFileFolders: any,
  setLoading: any,
  setCanRename: any
) => {
  try {
    setLoading(true);
    await axios.post(
      `${apiUrl}/api/v1/rename-folders`,
      body,
      { headers: { Authorization: accessToken } }
    );

    getFileFolders(accessToken);
    setLoading(false);
    setCanRename(false);
    toast.success("Folder renamed successfully");
  } catch (error: any) {
    setLoading(false);
    if (error?.response?.data?.status === 401) {
      logoutUser({});
      return;
    }

    toast.error(
      error?.response?.data?.message
        ? error?.response?.data?.message
        : "حدث خطأ ما"
    );
  }
};

export const deleteFolder = async (
  folderId: number,
  accessToken: string,
  getFileFolders: any,
  setLoading: any,
  fetchRecentFiles: any
) => {
  try {
    setLoading(true);
    await axios.delete(
      `${apiUrl}/api/v1/delete-folders/${folderId}`,
      { headers: { Authorization: accessToken } }
    );

    toast.success("Folder deleted successfully");
    getFileFolders(accessToken);
    fetchRecentFiles();
    setLoading(false);
  } catch (error: any) {
    setLoading(false);
    if (error?.response?.data?.status === 401) {
      logoutUser({});
      return;
    }

    toast.error(
      error?.response?.data?.message
        ? error?.response?.data?.message
        : "حدث خطأ ما"
    );
  }
};

export const deleteFile = async (
  fileId: number,
  accessToken: string,
  getFileFolders: any,
  setLoading: any,
  fetchRecentFiles: any
) => {
  try {
    setLoading(true);
    await axios.delete(
      `${apiUrl}/api/v1/delete-files/${fileId}`,
      { headers: { Authorization: accessToken } }
    );

    // toast.success('File deleted successfully')
    toast.success("تم حذف الملف بنجاح");
    getFileFolders();
    fetchRecentFiles();
    setLoading(false);
  } catch (error: any) {
    setLoading(false);
    if (error?.response?.data?.status === 401) {
      logoutUser({});
      return;
    }

    toast.error(
      error?.response?.data?.message
        ? error?.response?.data?.message
        : "حدث خطأ ما"
    );
  }
};

export const getFileAndFolders = async (
  accessToken: string,
  parentFolderId
) => {
  try {
    let url = `${apiUrl}/api/v1/get/folders-files`;
    if (parentFolderId) {
      url += `/${parentFolderId}`;
    }
    const response = await axios.get(url, {
      headers: { Authorization: accessToken },
    });

    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.status === 401) {
      logoutUser({});
      return;
    }

    toast.error(
      error?.response?.data?.message
        ? error?.response?.data?.message
        : "حدث خطأ ما"
    );
  }
};

export const getRecentFiles = async (
  accessToken: string,
  setRecentFiles: any
) => {
  try {
    let url = `${apiUrl}/api/v1/recent-files`;

    const response = await axios.get(url, {
      headers: { Authorization: accessToken },
    });
    // console.log(' fetching files resopnse', response)
    setRecentFiles(response?.data?.data);
  } catch (error: any) {
    if (error?.response?.data?.status === 401) {
      logoutUser({});
      return;
    }

    toast.error(
      error?.response?.data?.message
        ? error?.response?.data?.message
        : "حدث خطأ ما"
    );
  }
};

export const getRecentFilesBase64 = async (
  accessToken: string,
  setUserState: Function,
  id: number,
  toggle: Function
) => {
  try {
    let url = `${apiUrl}/api/v1/get-file-by-id/${id}`;

    const response = await axios.get(url, {
      headers: { Authorization: accessToken },
    });

    setUserState(response?.data?.data[0]?.pdf_base64);

    toggle();
  } catch (error: any) {
    if (error?.response?.data?.status === 401) {
      logoutUser({});
      return;
    }
  }
};

export const getRecentFilesBase64Convert = async (
  accessToken: string,
  setPdf: Function,
  id: number,
  base64ToBlob: Function,
  setPdfData: Function,
  setOpenModal: Function
) => {
  try {
    let url = `${apiUrl}/api/v1/get-file-by-id/${id}`;

    const response = await axios.get(url, {
      headers: { Authorization: accessToken },
    });

    setPdf(response?.data?.data[0]?.pdf_base64);
    const blob = base64ToBlob(response?.data?.data[0]?.pdf_base64);

    setPdfData(blob);
    setOpenModal(false);
  } catch (error: any) {
    if (error?.response?.data?.status === 401) {
      logoutUser({});
      return;
    }
  }
};

export const getHierarchyFolders = async (
  setHierarchyFolders: any,
  accessToken: string
) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/v1/get-hierarchy-folders`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      }
    );

    setHierarchyFolders(response.data.folders);
  } catch (error: any) {
    if (error?.response?.data?.status === 401) {
      logoutUser({});
      return;
    }
    // toast.error(
    //   error?.response?.data?.message
    //     ? error?.response?.data?.message
    //     : 'حدث خطأ ما while fetching '
    // )
  }
};

export const createFolderAccess = async (
  body: any,
  accessToken: string,
  setLoading: any,
  setOpenUserModal: any,
  getFileFolders: any
  // fetchRecentFiles: any
) => {
  try {
    setLoading(true);
    await axios.post(
      `${apiUrl}/api/v1/set-file-manager-access`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      }
    );

    getFileFolders();
    toast.success("Folder access assigned successfully");
    setOpenUserModal(false);
    setLoading(false);
    // fetchRecentFiles()
  } catch (error: any) {
    setLoading(false);
    if (error?.response?.data?.status === 401) {
      logoutUser({});
      return;
    }
    if (error?.response?.data?.error?.pdf) {
      toast.error(error.response.data.error.pdf[0]);
      return;
    }
    toast.error(
      error?.response?.data?.message
        ? error?.response?.data?.message
        : "حدث خطأ ما"
    );
  }
};

export const getMyFiles = async (accessToken: string, parentFolderId) => {
  try {
    let url = `${apiUrl}/api/v1/get-myfile-access`;
    if (parentFolderId) {
      url += `/${parentFolderId}`;
    }
    const response = await axios.get(url, {
      headers: { Authorization: accessToken },
    });

    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.status === 401) {
      logoutUser({});
      return;
    }

    toast.error(
      error?.response?.data?.message
        ? error?.response?.data?.message
        : "حدث خطأ ما"
    );
  }
};

export const getMyHierarchy = async (
  setHierarchyFolders: any,
  accessToken: string
) => {
  try {
    const response = await axios.get(
      `${apiUrl}/api/v1/my-hierarchy-folders`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      }
    );

    setHierarchyFolders(response.data.folders);
  } catch (error: any) {
    if (error?.response?.data?.status === 401) {
      logoutUser({});
      return;
    }
    // toast.error(
    //   error?.response?.data?.message
    //     ? error?.response?.data?.message
    //     : 'حدث خطأ ما while fetching '
    // )
  }
};

export const viewUserFiles = async (
  body: { user_id: string; parent_folder_id: string },
  accessToken: string
) => {
  try {
    let url = `${apiUrl}/api/v1/view-userfile-access`;
    const response = await axios.post(url, body, {
      headers: { Authorization: accessToken },
    });
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.status === 401) {
      logoutUser({});
      return;
    }

    toast.error(
      error?.response?.data?.message
        ? error?.response?.data?.message
        : "حدث خطأ ما"
    );
  }
};

export const viewUserHierarchyFiles = async (
  body: object,
  setHierarchyFolders: any,
  accessToken: string
) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/v1/view-user-hierarchy-folders`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      }
    );
    setHierarchyFolders(response?.data?.folders);
  } catch (error: any) {
    if (error?.response?.data?.status === 401) {
      logoutUser({});
      return;
    }
    // toast.error(
    //   error?.response?.data?.message
    //     ? error?.response?.data?.message
    //     : 'حدث خطأ ما while fetching '
    // )
  }
};

export const removeFolderAccess = async (
  folder_id: any,
  user_id: any,
  // getFileFolders: any,
  accessToken: string,
  // setLoading: any,
  onCloseClick: any,
  onClose: any,
  setLoading: any
) => {
  try {
    setLoading(true);
    await axios.post(
      `${apiUrl}/api/v1/remove-folder-access`,
      { user_id: user_id, folder_id: folder_id },
      { headers: { Authorization: accessToken } }
    );

    // toast.success('Access removed successfully')
    toast.success("تمت إزالة الوصول بنجاح ");
    onCloseClick();
    onClose();
    // getFileFolders();
    // fetchRecentFiles();
    setLoading(false);
  } catch (error: any) {
    setLoading(false);
    if (error?.response?.data?.status === 401) {
      logoutUser({});
      return;
    }

    toast.error(
      error?.response?.data?.message
        ? error?.response?.data?.message
        : "حدث خطأ ما"
    );
  }
};
