import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as Yup from "yup";
import ReactSignatureCanvas from "react-signature-canvas";

import { useFormik } from "formik";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import DeleteModal from "../../Components/Common/DeleteModal";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";

import {
  getAllSignature,
  createSignature,
  deleteSignature,
} from "../../apiServices/signature/index";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";

import { logOutToken } from "slices/auth/login/reducer";
import { ToastContainer, toast } from "react-toastify";

const EcommerceCustomers = () => {
  //meta title
  document.title = "Customers | Signready";

  const dispatch: any = useDispatch();
  const { accessToken } = useSelector((store: any) => {
    return store.Login;
  });

  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [allSignature, setAllSignature] = useState<any>([]);
  const [singleSignature, setSingleSignature] = useState({
    name: "",
    image: "",
  });
  const [userId, setUserId] = useState("");
  const [uploadSignatureState, setUploadSignature] = useState(false);
  const [state, setState] = useState<any>();
  const [uploadSign, setUploadSign] = useState(null);
  const [imageSign, setImageSign] = useState("");
  function EndSign() {
    setImageSign(state.getSignaturePad().toDataURL("image/png"));
  }

  const handleClear = () => {
    state?.clear();
    setImageSign("");
    setUploadSign(null);
  };
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      // setCustomer(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  // validation

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (singleSignature && singleSignature.name) || "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required(
        "الرجاء إدخال الاسم"
        // 'Please Enter Your Name'
      ),
    }),
    onSubmit: (values: any) => {
      const newCustomer = {
        name: values["name"],
      };
      if (uploadSignatureState === true) {
        if (uploadSign) {
          const reader: any = new FileReader();
          reader.onloadend = () => {
            createSignature(
              newCustomer,
              accessToken,
              setAllSignature,
              logoutFunction,
              toggle,
              reader?.result,
              setLoader,
              setImageSign
            );
            validation.resetForm();
            // setBase64Image(reader?.result);
          };
          reader.readAsDataURL(uploadSign);
        } else {
          // toast.error('Please Insert Your Signature')
          toast.error(" الرجاء إضافة توقيعك");
        }
      } else {
        if (imageSign) {
          createSignature(
            newCustomer,
            accessToken,
            setAllSignature,
            logoutFunction,
            toggle,
            imageSign,
            setLoader,
            setImageSign
          );
          validation.resetForm();
        } else {
          // toast.error('Please Insert Your Signature')
          toast.error(" الرجاء إضافة توقيعك");
        }
      }
    },
  });

  const logoutFunction = () => {
    dispatch(logOutToken({}));
  };

  const handleCustomerClick = useCallback(
    (arg: any) => {
      const customer = arg;

      setSingleSignature({
        name: customer.signature_name,
        image: customer.image,
      });
      setIsEdit(true);

      toggle();
    },
    [toggle]
  );

  // Customber Column
  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "id",
        cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        // header: 'Sign Name',
        header: "التوقيع",
        accessorKey: "signature_name",

        enableColumnFilter: true,
        enableSorting: true,
      },
      {
        // header: 'Image',
        header: "صورة",
        accessorKey: "image",

        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => {
          return (
            <>
              {cell.row.original.image && (
                <img
                  className="mb-0 "
                  width="100px"
                  height="auto"
                  alt=""
                  src={cell.row.original.image}
                />
              )}
            </>
          );
        },
      },

      {
        // header: 'Action',
        header: "إعدادات",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cellProps: any) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" href="#" className="card-drop">
                {" "}
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  href="#"
                  onClick={() => {
                    const customerData = cellProps.row.original;

                    onClickDelete(customerData);
                  }}
                >
                  <i
                    className="mdi mdi-trash-can font-size-16 text-danger me-1"
                    id="deletetooltip"
                  ></i>
                  {/* Delete */}حذف
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    {" "}
                    {/* Delete */}
                    حذف
                  </UncontrolledTooltip>
                </DropdownItem>
                <DropdownItem
                  href="#"
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    handleCustomerClick(customerData);
                    setUploadSignature(true);
                  }}
                >
                  {/* <span className="mdi mdi-eye"></span> */}
                  <i
                    // mdi-eye
                    className="mdi mdi-eye font-size-16 text-black me-1"
                    id="viewtooltip"
                  ></i>
                  {/* View */}
                  عرض
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    {" "}
                    {/* View */}
                    عرض
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [handleCustomerClick]
  );

  //delete customer
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const onClickDelete = (customer: any) => {
    setUserId(customer.id);
    // setCustomer(customer);
    setDeleteModal(true);
  };

  const handleCustomerClicks = () => {
    validation.resetForm();
    setUploadSignature(false);
    setImageSign("");
    setUploadSign(null);
    setSingleSignature({
      name: "",
      image: "",
    });
    setIsEdit(false);
    // // setCustomer("");
    toggle();
  };

  const handleDeleteCustomer = () => {
    if (userId) {
      deleteSignature(
        accessToken,
        userId,
        setAllSignature,
        logoutFunction,
        setUserId,
        setLoader
      );
    }
    setDeleteModal(false);
  };

  useEffect(() => {
    if (accessToken) {
      getAllSignature(accessToken, setAllSignature, logoutFunction, setLoader);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCustomer}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title=""
            breadcrumbItem="تواقيع"
            // breadcrumbItem="Signatures"
          />
          {loader === true ? (
            <div className="loader-box">
              <div className="loader"></div>
            </div>
          ) : (
            ""
          )}

          <Col xs="12">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={allSignature}
                  // isGlobalFilter={true}
                  isAddButton={true}
                  // isPagination={true}
                  isCustomPageSize={true}
                  handleUserClick={handleCustomerClicks}
                  buttonClass="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2 addCustomers-modal"
                  // buttonName="Add Signature"
                  buttonName="اضافة توقيع"
                  paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                  tableClass="align-middle table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                  theadClass="table-light align-middle"
                  pagination="pagination"
                  stateData={true}
                />
              </CardBody>
            </Card>
          </Col>

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {/* {!!isEdit ? 'View' : 'Add Signature'} */}
              {!!isEdit ? "عرض" : "اضافة توقيع"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e: any) => {
                  e.preventDefault();
                  validation.handleSubmit();

                  return false;
                }}
              >
                <Row>
                  <Col xs={12}>
                    <div className="mb-3">
                      <Label>
                        {/* name */}
                        اسم
                      </Label>
                      <Input
                        name="name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        // placeholder="Insert name"
                        placeholder="إدراج اسم"
                        invalid={
                          validation.touched.name && validation.errors.name
                            ? true
                            : false
                        }
                        disabled={!!isEdit === true ? true : false}
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.name}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        value="option1"
                        checked={uploadSignatureState === true ? true : false}
                        onChange={(e) => {
                          setUploadSignature(true);
                        }}
                      />
                      <label className="form-check-label">تحميل التوقيع</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio2"
                        value="option2"
                        checked={uploadSignatureState === false ? true : false}
                        onChange={(e) => {
                          setUploadSignature(false);
                        }}
                      />
                      <label className="form-check-label">قم بالتوقيع</label>
                    </div>
                    <div className="mb-3 signature d-flex justify-content: space-between; shadow-[#50d71e] ">
                      {!!isEdit ? (
                        <img
                          src={singleSignature.image}
                          alt="not found"
                          className="sigCanvas"
                        />
                      ) : uploadSignatureState === false ? (
                        <ReactSignatureCanvas
                          penColor={"black"}
                          ref={(data) => {
                            setState(data);
                          }}
                          onEnd={() => EndSign()}
                          canvasProps={{
                            className: "sigCanvas",
                          }}
                          from
                        />
                      ) : (
                        <>
                          <div className="upload-btn cursor-pointer">
                            <button className="upload-icon cursor-pointer">
                              تحميل التوقيع
                              <i className="mdi-upload mdi font-size-20 text-white me-1"></i>
                            </button>

                            <input
                              type="file"
                              className="cursor-pointer"
                              onChange={(e: any) => {
                                setUploadSign(e.target.files[0]);
                              }}
                            />
                          </div>
                          {uploadSign && (
                            // eslint-disable-next-line
                            <img
                              className="custom-margin"
                              src={URL.createObjectURL(uploadSign)}
                              height="100px"
                              width="130px"
                            />
                          )}
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  {isEdit !== true && (
                    <>
                      <Col>
                        <div className="modal-btn-box">
                          <div className="">
                            <Button
                              onClick={handleClear}
                              color="success"
                              className="save-customer"
                            >
                              إعادة التوقيع
                            </Button>
                          </div>
                          <div className="">
                            <Button
                              type="submit"
                              color="success"
                              className="save-customer"
                            >
                              {!!isEdit ? "حفظ" : "إضافة"}
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>

    ///
  );
};

export default EcommerceCustomers;
