/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { DrawCall } from "./pdfeditor";
import { Viewer } from "./view";
import { PDFEditorSignatures, MouseEvent } from "./pdfeditor";
import { MouseEventType } from "./pdfeditor.enum";
import Select from "react-select";
import { getAllUsers } from "../../apiServices/assignpdf/index";
import { useDispatch, useSelector } from "react-redux";
import { logOutToken } from "slices/auth/login/reducer";
import { savePdfwithSignature } from "../../apiServices/addpdf/index";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Label } from "reactstrap";
// import "./style.css";

const MultiSelectcustomStyles = {
  input: (provided: any, state: any) => ({
    ...provided,
    color: "#FFF",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "red" : "#fff",
    backgroundColor: state.isSelected ? "#006cf9" : "#0c2144",
    "&:hover": {
      backgroundColor: "#006cf9",
    },
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    color: "#fff",
    backgroundColor: "#0c2144",
    borderColor: "rgba(60 ,86 ,124, 50%)",
    "&:hover": {
      borderColor: "rgba(60 ,86 ,124, 50%)",
    },
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: "#fff",
    // color: state.isFocused
    //   ? "rgba(209, 228, 255, 0.5)"
    //   : "rgba(209, 228, 255, 0.5)",
  }),
};

interface EditorProps {
  pdf: string;
  pdfData: any;
  setLoader: any;
  folderId: any;
}
const signatureWidth = 180;
const signatureHeight = 25;
let dataIncreament = 0;

const Editor: React.FC<EditorProps> = (props: EditorProps) => {
  /* draw class and signature data */
  const [drawCalls, setDrawCalls] = React.useState<{
    [page: number]: DrawCall[];
  }>({});
  const [allSignatureData, setAllSignatureData] = React.useState<{
    [pageNumber: number]: PDFEditorSignatures[];
  }>([]);

  const navigate = useNavigate();
  const [signatureData, setSignatureData] =
    React.useState<PDFEditorSignatures | null>(null);
  const email = React.useRef<string>("");
  const { accessToken } = useSelector((store: any) => {
    return store.Login;
  });

  /*Flags*/
  const [addSignature, setAddSignature] = React.useState<boolean>(false);
  const [selectedPages, setSelectedPages] = useState([]);
  const [typeSet, setType] = useState({
    label: "",
    value: "",
  });

  const mouseDown = React.useRef<boolean>(false);
  const [users, setUsers] = useState([]);
  const [usersInfo, setUserInfo] = useState({
    label: "",
    value: "",
  });
  const [userData, setUserData] = useState<any>([]);

  const blockSelected = React.useRef<number>(-1);
  const dispatch = useDispatch();
  

  const onCanvasClicked = (event: MouseEvent | TouchEvent) => {
    ////////////////////// مشكلة اللمس
    if ('touches' in event) {
    console.log('touches');
      handleTouchEvent(event as TouchEvent);
    } else {
      handleMouseEvent(event as MouseEvent);
    }
  };
  
  const handleMouseEvent = (mouseEvent: MouseEvent) => {
    switch (mouseEvent.type) {
      case MouseEventType.MOUSE_DOWN:
       
        eventHandleDown(mouseEvent);
        break;
      case MouseEventType.MOUSE_UP:
       
        eventHandleMouseUp(mouseEvent);
        break;
      case MouseEventType.MOUSE_MOVE:
       
        eventHandleMouseMove(mouseEvent);
        break;
      case MouseEventType.MOUSE_LEAVE:
       
        mouseDown.current = false;
        break;
      default:
        break;
    }
  };
  
  const handleTouchEvent = (touchEvent: TouchEvent) => {
    const touch = touchEvent.touches[0];
    const mouseEvent: MouseEvent = {
      pos: { x: touch.clientX, y: touch.clientY },
      type: touchEvent.type as MouseEventType, // Ensure the type matches
      page: 0, // Determine the page based on your application logic
    };
    
    switch (touchEvent.type) {
      case 'touch_start':
        console.log('Tmove');
        eventHandleDown(mouseEvent);
        break;
      case 'touch_end':
        console.log('touchend');
        eventHandleMouseUp(mouseEvent);
        break;
      case 'touch_move':
        console.log('touchmove');
        eventHandleMouseMove(mouseEvent);
        break;
      case 'touch_cancel':
        console.log('touchcancel');
        mouseDown.current = false;
        break;
      default:
        break;
    }
  };

  const eventHandleDown = (mouseEvent: MouseEvent) => {
    try {
      
      if (addSignature) {
        //loop through and make srue email hasnt' been used on the paage yet
        if (allSignatureData[mouseEvent.page]) {
          allSignatureData[mouseEvent.page].forEach((signature) => {
            if (signature.email === email.current) {
              // toast.error("Email already used on this page");
              // alert("Email already used on this page");
              setAddSignature(false);
              setSignatureData(null);
              throw new Error("Email already used on this page");
            }
          });
        }
        if (signatureData?.email) {
          const newPosition = {
            x: mouseEvent.pos.x - signatureWidth / 2,
            y: mouseEvent.pos.y - signatureHeight / 2,
          };

          var newSignatureData = signatureData;

          if (newSignatureData == null) {
            return false;
          }

          newSignatureData.blockDrawCall = {
            type: "rect",
            position: newPosition,
            size: {
              width: signatureWidth,
              height: signatureHeight,
            },
            data: "black",
          };
          newSignatureData.textDrawCall = {
            type: "text",
            position: {
              x: newPosition.x + 60,
              y: newPosition.y + 15,
            },
            size: {
              width: 0,
              height: 0,
            },
            data: email.current,
          };

          var newSignatures = { ...allSignatureData };
          if (!newSignatures[mouseEvent.page]) {
            newSignatures[mouseEvent.page] = [];
          }

          newSignatures[mouseEvent.page].push(newSignatureData);
          setAllSignatureData(newSignatures);
          setAddSignature(false);
        }
      } else {
        mouseDown.current = true;

        if (allSignatureData[mouseEvent.page]) {
          allSignatureData[mouseEvent.page].forEach((call, index) => {
            if (call.blockDrawCall == null || call.textDrawCall == null) {
              throw new Error("Block Draw Call or Text Draw Call is null");
            }
            
            if (
              mouseEvent.pos.x > call.blockDrawCall.position.x &&
              mouseEvent.pos.x <
                call.blockDrawCall.position.x + call.blockDrawCall.size.width &&
              mouseEvent.pos.y > call.blockDrawCall.position.y &&
              mouseEvent.pos.y <
                call.blockDrawCall.position.y + call.blockDrawCall.size.height
            ) {
              blockSelected.current = index;
            }
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const eventHandleMouseUp = (mouseEvent: MouseEvent) => {
    mouseDown.current = false;
  };

  const eventHandleMouseMove = (mouseEvent: MouseEvent) => {
    try {
      
      if (mouseDown.current && blockSelected.current !== -1) {
        const newSignatureData =
          allSignatureData[mouseEvent.page][blockSelected.current];

        if (
          newSignatureData.blockDrawCall == null ||
          newSignatureData.textDrawCall == null
        ) {
          throw new Error("Block Draw Call or Text Draw Call is null");
        }
        newSignatureData.blockDrawCall.position = {
          x: mouseEvent.pos.x - signatureWidth / 2,
          y: mouseEvent.pos.y - signatureHeight / 2,
        };
        newSignatureData.textDrawCall.position = {
          x: newSignatureData.blockDrawCall.position.x + 60,
          y: newSignatureData.blockDrawCall.position.y + 15,
        };
        setAllSignatureData({
          ...allSignatureData,
          [mouseEvent.page]: [
            ...allSignatureData[mouseEvent.page].slice(
              0,
              blockSelected.current
            ),
            newSignatureData,
            ...allSignatureData[mouseEvent.page].slice(
              blockSelected.current + 1
            ),
          ],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    if (!addSignature) {
      setSignatureData(null);
      email.current = "";
    }
  }, [addSignature]);

  React.useEffect(() => {
    try {
      const newDrawCalls: { [page: number]: DrawCall[] } = {};
      for (let page in allSignatureData) {
        newDrawCalls[page] = [];

        allSignatureData[page].forEach((signature) => {
          if (
            signature.blockDrawCall == null ||
            signature.textDrawCall == null
          ) {
            throw new Error("Block Draw Call or Text Draw Call is null");
          }
          newDrawCalls[page].push(signature.blockDrawCall);
          newDrawCalls[page].push(signature.textDrawCall);
        });
      }
      setDrawCalls(newDrawCalls);
    } catch (error) {
      console.log(error);
    }
  }, [allSignatureData]);
  const logoutFunction = () => {
    dispatch(logOutToken(accessToken));
  };
  useEffect(() => {
    getAllUsers(accessToken, setUsers, logoutFunction, props?.setLoader);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [boxState, setBoxState] = useState<any>([]);
  const [boxSize, setBoxSize] = useState<any>([]);
  const [textState, setTextState] = useState<any>([]);
  const [textSize, setTextSize] = useState<any>([]);
  const [pageNumberForm, setPageNumber] = useState<any>([]);
  const [pageIndex, setPageIndex] = useState<any>([]);
  const [userIdData, setUserIdData] = useState<any>([]);
  const [signatureTypeInfo, setSignatureTypeInfo] = useState<any>([]);
  const [order, setOrder] = useState<any>([]);
  const adminSigantureData = (
    pages: any,
    email: any,
    userId: any,
    type: string
  ) => {
    // Create an object to accumulate signature data for all pages
    const newSignatureDataForAllPages: any = {};
    // if (userId) {
    // if (type) {
    if (pages?.length !== 0) {
      for (let i = 0; i < pages?.length; i++) {
        ++dataIncreament;

        const page = pages[i].value;

        if (addSignature) {
          // Check if the user's email already exists on the same page
          const existingSignaturesOnPage = allSignatureData[page] || [];
          const emailExistsOnPage = existingSignaturesOnPage.some(
            (signature) => signature.email === email
          );
          // if (emailExistsOnPage) {
          //   // toast.error("User already exists on this page");
          //   toast.error("المستخدم متواجد في هذه الصفحة");
          //   return; // Stop further execution
          // }

          // Calculate the position for the signature
          const newPosition = {
            x: 208,
            y: 431.5,
          };

          // Create new signature data
          const newSignatureData: any = {
            email: email,
            order: String(dataIncreament),
            blockDrawCall: {
              type: "rect",
              position: newPosition,
              size: {
                width: 180,
                height: 25,
              },
              data: "black",
            },
            signatureType: type ? type : "",
            userId: userId,
            textDrawCall: {
              type: "text",
              position: {
                x: newPosition.x + 60,
                y: newPosition.y + 15,
              },
              size: {
                width: 0,
                height: 0,
              },
              data: email,
            },
          };

          // Store the new signature data for the page separately
          newSignatureDataForAllPages[page] = newSignatureData;
        }
      }

      // Merge the new signature data into the existing data in the order specified by the pages array
      const mergedSignatureData: any = {};
      pages &&
        pages?.forEach((page: any) => {
          const pageValue = page.value;
          mergedSignatureData[pageValue] = [
            ...(newSignatureDataForAllPages[pageValue]
              ? [newSignatureDataForAllPages[pageValue]]
              : []),
            ...(allSignatureData[pageValue] || []),
          ];
        });

      // Update the allSignatureData state with the merged signature data
      setAllSignatureData((prevData) => ({
        ...allSignatureData,
        ...mergedSignatureData,
      }));
    } else {
      // toast.error("Please Select the Page");
      toast.error("الرجاء اختيار الصفحة");
    }
    // } else {
    //   toast.error("الرجاء تحديد النوع");
    // }
  };
  //  else {
  //   // toast.error("Please Select the User");
  //   toast.error("الرجاء تحديد المستخدم");
  //
  

  const functionData = (enable_hierarchy: boolean) => {
    if (Object.entries(allSignatureData).length > 0) {
      for (const [pageNumber, signatures] of Object.entries(allSignatureData)) {
        // Removed the variable pageNumberFound, as pageNumber can be used directly

        if (signatures) {
          signatures.forEach((signature: any, index) => {
            const newObject: any = signature?.blockDrawCall?.position;
            const newObject1: any = signature?.blockDrawCall?.size;
            const newObject2: any = signature?.textDrawCall?.position;
            const newObject3: any = signature?.textDrawCall?.size;
            const newObject4: any = signature?.signatureType;
            userIdData.push(signature?.userId);
            order.push(signature?.order);
            boxState.push(newObject);
            boxSize.push(newObject1);
            textState.push(newObject2);
            textSize.push(newObject3);
            pageNumberForm.push(pageNumber); // Using pageNumber directly
            signatureTypeInfo.push(newObject4);
          });
        }
      }

      savePdfwithSignature(
        accessToken,
        props.pdfData,
        userData,
        allSignatureData,
        props?.setLoader,
        logoutFunction,
        boxState,
        boxSize,
        textState,
        textSize,
        navigate,
        enable_hierarchy,
        pageNumberForm,
        pageIndex,
        userIdData,
        order,
        props?.folderId,
        signatureTypeInfo
      );
    } else {
      // toast.error("Assign Users First");
      toast.error("يجب تعيين مستخدم أولاً");
    }
  };

  useEffect(() => {
    dataIncreament = 0;
  }, []);
  return (
    <>
      <div className="container">
        <ToastContainer />
        {/* <div className="mb-3">
          <Label>Pdf</Label>
          <Select styles={MultiSelectcustomStyles} />
        </div> */}
        <Viewer
          pdf={props.pdf}
          drawCalls={drawCalls}
          eventHandler={onCanvasClicked}
          setAddSignature={setAddSignature}
          addSignature={addSignature}
          functionData={functionData}
          signatureData={signatureData}
          users={users}
          setUserInfo={setUserInfo}
          email={email}
          usersInfo={usersInfo}
          setUserData={setUserData}
          setSignatureData={setSignatureData}
          setSelectedPages={setSelectedPages}
          adminSigantureData={adminSigantureData}
          selectedPages={selectedPages}
          typeSet={typeSet}
          setType={setType}
        />
      </div>
    </>
  );
};

export { Editor };
