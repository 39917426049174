import React, { useState, useCallback } from "react";
import { Editor } from "./editor";
import { useDropzone } from "react-dropzone";
import { cleanBorder, primary45 } from "../Utility/colors";
import { blobToURL } from "../Utility/Utils";
import FileManagementModal from "pages/FileManagerModal";
import { ToastContainer } from "react-toastify";
export default function Index() {
  const [pdf, setPdf] = useState<any>("");
  const [pdfData, setPdfData] = useState();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [folderId, setFolderId] = useState();

  const styles: any = {
    container: {
      textAlign: "center",
      border: cleanBorder,
      padding: 20,
      color: primary45,
      fontSize: 18,
      fontWeight: 600,
      borderRadius: 4,
      userSelect: "none",
      outline: 0,
      cursor: "pointer",
      width: "100%",
      maxWidth: "300px",
      height: "120px",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "2rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#c1e3f6",
      // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    },
  };
  const onLoaded = async (files: any) => {
    const URL = await blobToURL(files[0]);
    setPdfData(files[0]);
    setPdf(URL);
  };
  const onDrop = useCallback((acceptedFiles: any) => {
    onLoaded(acceptedFiles);
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const [loader, setLoader] = useState(false);
  return (
    <div>
      <ToastContainer />
      {loader === true ? (
        <div className="loader-box">
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      {!pdf && (
        <>
        {/*
          <div className="file-select">
            <button
              onClick={(e) => {
                setOpenModal(true);
              }}
            >
              
              حدد ملف من مدير الملفات
            </button>
            <div className="column-divider">
              <span className="divider-text">
                أو
              </span>
            </div>
          </div>
          */}

          <div {...getRootProps()} style={styles.container}>
            <input {...getInputProps()} />
            {/* {isDragActive ? <p>Drop a PDF here</p> : <p>Drag a PDF here</p>} */}
            {isDragActive ? (
              <p>قم بإسقاط ملف هنا</p>
            ) : (
              <p>اسحب ملف هنا</p>
            )}
          </div>
        </>
      )}

      {pdf ? (
        <Editor
          pdf={pdf}
          pdfData={pdfData}
          setLoader={setLoader}
          folderId={folderId}
        />
      ) : (
        ""
      )}

      <FileManagementModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        setPdf={setPdf}
        setPdfData={setPdfData}
        setFolderId={setFolderId}
      />
    </div>
  );
}
