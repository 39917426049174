import React, { useEffect, useState } from 'react'

// Redux
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  Label,
  FormFeedback,
} from 'reactstrap'

// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// // import images
// import profile from '../../assets/images/profile-img.png'
import logo from '../../assets/images/Signready_icon.png'
import lightlogo from '../../assets/images/logo-light.svg'

//import thunk
import { loginUser } from 'slices/auth/login/thunk'
import withRouter from 'Components/Common/withRouter'
import { ToastContainer } from 'react-toastify'

const Login = (props: any) => {
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const dispatch: any = useDispatch()
  const { accessToken, loading } = useSelector((store: any) => {
    return store.Login
  })

  //meta title
  document.title = 'تسجيل الدخول | Signready'

  // Form validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('ادخل البريد الإلكتروني'),
      // 'Please Enter Your email'
      password: Yup.string().required('ادخل كلمة المرور'),
      // 'Please Enter Your Password'
    }),
    onSubmit: async (values: any) => {
      dispatch(loginUser(values))
    },
  })

  useEffect(() => {
    if (accessToken) {
      navigate('/')
    }
    // eslint-disable-next-line
  }, [accessToken])
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        {loading === true ? (
          <div className="loader-box">
            <div className="loader"></div>
          </div>
        ) : (
          ''
        )}
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle-self">
                  <Row>
                    <Col className="col-7">
                      <div className="bg-layout-text p-4">
                        <h5 className="bg-layout-text">
                          {/* Welcome Back ! */}
                          مرحباً بك!
                          </h5>
                        <p>
                          {/* Sign in to continue to Signready. */}
                          الرجاء تسجيل الدخول
                          </p>
                      </div>
                    </Col>
                    {/* <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col> */}
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={lightlogo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {/* Email */} 
                          البريد الإلكتروني
                          </Label>
                        <Input
                          name="email"
                          className="form-control"
                          // placeholder="Enter email"
                          placeholder='ادخل البريد الإلكتروني'
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">
                          {/* Password */}
                          كلمة المرور
                          </Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="password"
                            value={validation.values.password || ''}
                            type={show ? 'text' : 'password'}
                            // placeholder="Enter Password"
                            placeholder='ادخل كلمة المرور'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          <button
                            onClick={() => setShow(!show)}
                            className="btn btn-light "
                            type="button"
                            id="password-addon">
                            <i className="mdi mdi-eye-outline"></i>
                          </button>
                        </div>
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline">
                          {/* Remember me */}
                          تذكرني
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit">
                          {/* Log In */}
                          تسجيل الدخول
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/recoverpw" className="text-muted">
                          <i className="mdi mdi-lock me-1" /> 
                          {/* Forgot your password? */}
                          نسيت كلمة المرور؟
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Don&apos;t have an account ?{' '}
                  <Link to="/register" className="fw-medium text-primary">
                    {' '}
                    Signup now{' '}
                  </Link>{' '}
                </p> */}
                {/* <p>© {new Date().getFullYear()} Signready</p> */}
              </div>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)
