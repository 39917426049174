/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Renderer } from "./renderer";
import { DrawCall, MouseEvent } from "./pdfeditor";
import { GlobalWorkerOptions, PDFDocumentProxy, getDocument } from "pdfjs-dist";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import Select from "react-select";
// Setting worker path to worker bundle.
GlobalWorkerOptions.workerSrc =
  window.location.origin + "/assets/pdf.worker.js";
const MultiSelectcustomStyles = {
  input: (provided: any, state: any) => ({
    ...provided,
    color: "#FFF",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "red" : "#fff",
    backgroundColor: state.isSelected ? "#006cf9" : "#0c2144",
    "&:hover": {
      backgroundColor: "#006cf9",
    },
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    color: "#fff",
    backgroundColor: "#0c2144",
    borderColor: "rgba(60 ,86 ,124, 50%)",
    "&:hover": {
      borderColor: "rgba(60 ,86 ,124, 50%)",
    },
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: "#fff",
    // color: state.isFocused
    //   ? "rgba(209, 228, 255, 0.5)"
    //   : "rgba(209, 228, 255, 0.5)",
  }),
};
interface ViewerProps {
  pdf: string;
  drawCalls: { [page: number]: DrawCall[] };
  eventHandler: (mouseEvent: MouseEvent) => void;
  setAddSignature: any;
  addSignature: any;
  functionData: any;
  signatureData: any;
  users: any;
  setUserInfo: any;
  email: any;
  usersInfo: any;
  setUserData: any;
  setSignatureData: any;
  setSelectedPages: any;
  adminSigantureData: any;
  selectedPages: any;
  typeSet: any;
  setType: Function;
}

const Viewer: React.FC<ViewerProps> = (props: ViewerProps) => {
  const maxZoom = 3;
  const minZoom = 0.5;
  const pdf = props.pdf;
  const [pdfDoc, setPDFDocumentProxy] = React.useState<
    PDFDocumentProxy | undefined
  >(undefined);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openInitialModal, setInitialModal] = useState<boolean>(false);
  const [enableHierarchy, setEnableHierarchy] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([]);
  const { accessToken } = useSelector((store: any) => {
    return store.Login;
  });
  const { isAdmin } = useSelector((store: any) => store?.Login?.user);
  const [optionTypes, setOptionTypes] = useState([
    {
      label: "التأشير",
      value: "Only Initials",
    },
    {
      label: "التوقيع فقط",
      value: "Only Signature",
    },
    {
      label: "التأشير + التوقيع",
      value: "Initials and Signature",
    },
  ]);
  const toggle = () => setOpenModal(!openModal);
  const toggleInitialModal = () => setInitialModal(!openInitialModal);
  const [pageCount, setPageCount] = React.useState<number>(0);

  const [zoom, setZoom] = React.useState<number>(1);
  // const [canvasDimensions, setCanvasDimensions] = React.useState<{
  //     width: number;
  //     height: number;
  // }>({ width: 0, height: 0 });

  const loadPdf = () => {
    if (!pdf) {
      return;
    }
    const dataPdf = pdf;
    getDocument(dataPdf)
      .promise.then((pdfDoc: PDFDocumentProxy) => {
        setPDFDocumentProxy(pdfDoc);
        setPageCount(pdfDoc.numPages);
        setCurrentPage(1);
      })
      .catch((err) => {
        alert(err.message);
        return null;
      });
    if (!pdfDoc) {
      return;
    }
  };
  const changePage = (dir: number) => {
    const newPage = currentPage + dir;
    if (newPage > pageCount || newPage <= 0) {
      return;
    }
    setCurrentPage(newPage);
  };

  const zoomChange = (dir: number) => {
    const newZoom = zoom + dir;
    if (newZoom >= maxZoom || newZoom <= minZoom) {
      return;
    }
    setZoom(newZoom);
  };

  React.useEffect(() => {
    loadPdf();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const tempOptions: any = []; // Temporary array to accumulate options

    for (let i = 0; i < pageCount; i++) {
      tempOptions.push({
        value: i + 1,
        label: `page ${i + 1}`,
      });
    }

    setOptions(tempOptions); // Update options state after the loop completes
  }, [pageCount]);
  const filteredOptions = props?.users?.filter((user: any) => {
    return user.isAdmin >= isAdmin || user.isAdmin === (isAdmin - 1);
  });

  return (
    <>
      <div className="toolbar row justify-content-end">
        <div className="col-4 ">
          <div className="arrow--btn">
            <button
              className="btn btn-success page"
              id="prev_page"
              onClick={() => {
                changePage(-1);
              }}
            >
              <i className="fas fa-arrow-left"></i>
            </button>
            <span id="page_num">{currentPage}</span>
            <span>/</span>
            <span id="page_count">{pageCount}</span>
            <button
              className="btn btn-success page"
              id="next_page"
              onClick={() => {
                changePage(1);
              }}
            >
              <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
        <div className="col-3">
          <div className="arrow--btn">
            <button
              className="btn btn-success zoom"
              id="zoom_in"
              onClick={() => zoomChange(0.1)}
            >
              <i className="fas fa-plus"></i>
            </button>
            <button
              className="btn btn-success zoom"
              id="zoom_out"
              onClick={() => zoomChange(-0.1)}
            >
              <i className="fas fa-minus"></i>
            </button>
          </div>
        </div>
      </div>

      <div className="btn-content">
        <div id="canvas_wrapper" className="canvas_wrapper">
          <Renderer
            drawCalls={props.drawCalls}
            pdf={pdfDoc}
            zoom={zoom}
            page={currentPage}
            eventHandler={props.eventHandler}
          />
        </div>

        <div className="row">
          <div className="" id="editor-side-bar">
            <div className="">
              <div className="btn-box">
                <div className="add-sign-btn">
                  <button
                    className="btn btn-success signature-btn"
                    onClick={() => {
                      props.setAddSignature(true);
                      props?.setUserInfo({
                        label: "",
                        value: "",
                      });
                      props?.setType({
                        label: "",
                        value: "",
                      });
                      props?.setSelectedPages([]);
                      setOpenModal(true);
                    }}
                  >
                    {/* Add{props.addSignature && "ing"} Signature */}
                    إضافة توقيع
                  </button>
                </div>
                <br />
                {/* التأشير
                <div className="add-sign-btn">
                  <button
                    className="btn btn-success signature-btn"
                    onClick={() => {
                      props.setAddSignature(true);
                      props?.setUserInfo({
                        label: "",
                        value: "",
                        isAdmin:"",
                      });
                      props?.setType({
                        label: "",
                        value: "",
                        isAdmin:"",
                      });
                      props?.setSelectedPages([]);
                      setInitialModal(true);
                    }}
                  >
                      
                    أضف التأشير
                  </button>
                 
                </div>
                 */}
                <div>
                  <label className="btn  signature-btn">
                    <span style={{ verticalAlign: "top", fontSize: "0.8rem" }}>
                      {/* Enable Hierarchy */}
                      تفعيل التوقيع التسلسلي
                    </span>
                    <Toggle
                      icons={false}
                      defaultChecked={enableHierarchy}
                      onChange={() => setEnableHierarchy(!enableHierarchy)}
                    />
                  </label>
                </div>
                <br />
                <div>
                  <button
                    className="btn btn-success signature-btn"
                    onClick={() => props.functionData(enableHierarchy)}
                  >
                    حفظ
                    {/* Save */}
                  </button>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        toggle={toggle}
        isOpen={openModal}
        handleclose={() => {
          props?.setAddSignature(false);
        }}
      >
        
        <ModalHeader toggle={toggle} tag="h4">
          {/* Assign Users */}
          تعيين المستخدمين
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e: any) => {
              e.preventDefault();

              return false;
            }}
          >
            <Row>
            

              <Col xs={12}></Col>
              <label>مستخدم</label>
              <Select
              
                options={filteredOptions}
                value={props.usersInfo}
                styles={MultiSelectcustomStyles}
                onChange={(e: any) => {
                  props.setUserInfo({
                    label: e.name,
                    value: e.value,
                    isAdmin: e.isAdmin,
                  });
                  props.email.current = e.name;
                }}
                
              />
            </Row>

            <div className="mb-3 mt-3">
              <Label>
                {/* Select Page */}
                اختر الصفحة
              </Label>
              <Select
                styles={MultiSelectcustomStyles}
                options={options}
                isMulti
                onChange={(e) => {
                  props?.setSelectedPages(e);
                }}
                // value={project}
                // onChange={(e: any) => {
                //   setProject({
                //     value: e.value,
                //     label: e.label,
                //   });
                // }}
                // isDisabled={isEdit ? true : false}
              />
            </div>
            {/* <div className="mb-3 mt-3">
              <Label>يكتب</Label>
              <Select
                styles={MultiSelectcustomStyles}
                options={optionTypes}
                onChange={(e: any) => {
                  props?.setType({
                    value: e.value,
                    label: e.label,
                  });
                }}
              />
            </div> */}
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    className="btn btn-success"
                    onClick={(e) => {
                      const newSignatureData = {
                        email: props.email.current,
                      };
                      // eslint-disable-next-line
                      {
                        if (props?.selectedPages.length !== 0) {
                          for (
                            let i = 0;
                            i < props?.selectedPages.length;
                            i++
                          ) {
                            props.setUserData((prevArray: any) => [
                              ...prevArray,
                              props.usersInfo.value,
                            ]);
                          }
                        } else {
                          props.setUserData((prevArray: any) => [
                            ...prevArray,
                            props.usersInfo.value,
                          ]);
                        }
                      }
                      props.setSignatureData(newSignatureData);
                      props.setUserInfo({
                        label: "",
                        value: "",
                        isAdmin:"",
                      });
                      toggle();
                      props.adminSigantureData(
                        props?.selectedPages,
                        props.email.current,
                        props.usersInfo.value,
                        null
                      );
                    }}
                  >
                    {/* Save */}
                    حفظ
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      props.setAddSignature(false);
                      toggle();
                    }}
                  >
                    {/* Cancel */}
                    إلغاء
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      {/* initialModal */}

      <Modal
        toggle={toggleInitialModal}
        isOpen={openInitialModal}
        handleclose={() => {
          props?.setAddSignature(false);
        }}
      >
        <ModalHeader toggle={toggleInitialModal} tag="h4">
          {/* Assign Users */}
          تعيين المستخدمين
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e: any) => {
              e.preventDefault();

              return false;
            }}
          >
            <Row>
              <Col xs={12}></Col>
              {/* <label>لبريد الإلكتروني:</label> */}
              <label>مستخدم</label>
              <Select
                options={filteredOptions}
                value={props.usersInfo}
                styles={MultiSelectcustomStyles}
                onChange={(e: any) => {
                  props.setUserInfo({
                    label: e.name,
                    value: e.value,
                    isAdmin:e.isAdmin,
                  });
                  props.email.current = e.name;
                }}
              />
            </Row>

            <div className="mb-3 mt-3">
              <Label>
                {/* Select Page */}
                اختر الصفحة
              </Label>
              <Select
                styles={MultiSelectcustomStyles}
                options={options}
                isMulti
                onChange={(e) => {
                  props?.setSelectedPages(e);
                }}
                // value={project}
                // onChange={(e: any) => {
                //   setProject({
                //     value: e.value,
                //     label: e.label,
                //   });
                // }}
                // isDisabled={isEdit ? true : false}
              />
            </div>
            {/* <div className="mb-3 mt-3">
              <Label>يكتب</Label>
              <Select
                styles={MultiSelectcustomStyles}
                options={optionTypes}
                onChange={(e: any) => {
                  props?.setType({
                    value: e.value,
                    label: e.label,
                  });
                }}
              />
            </div> */}
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    className="btn btn-success"
                    onClick={(e) => {
                      const newSignatureData = {
                        email: props.email.current,
                      };
                      // eslint-disable-next-line
                      {
                        if (props?.selectedPages.length !== 0) {
                          for (
                            let i = 0;
                            i < props?.selectedPages.length;
                            i++
                          ) {
                            props.setUserData((prevArray: any) => [
                              ...prevArray,
                              props.usersInfo.value,
                            ]);
                          }
                        } else {
                          props.setUserData((prevArray: any) => [
                            ...prevArray,
                            props.usersInfo.value,
                          ]);
                        }
                      }
                      props.setSignatureData(newSignatureData);
                      props.setUserInfo({
                        label: "",
                        value: "",
                        isAdmin: "",
                      });
                      toggleInitialModal();
                      props.adminSigantureData(
                        props?.selectedPages,
                        props.email.current,
                        props.usersInfo.value,
                        "initials"
                      );
                    }}
                  >
                    {/* Save */}
                    حفظ
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      props.setAddSignature(false);
                      toggleInitialModal();
                    }}
                  >
                    {/* Cancel */}
                    إلغاء
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export { Viewer };
