const getApiUrl = () => {
    const { protocol, hostname, port } = window.location;
    return `${protocol}//${hostname}:${port || '8443'}`;
  };
  
  const getApiUrlnoport = () => {
    const { protocol, hostname, port } = window.location;
    return `${protocol}//${hostname}`;
  };

  export const apiUrl = getApiUrl();
  export const apiUrlnoport = getApiUrlnoport();