import React, { useState } from 'react'

// Formik Validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { Link } from 'react-router-dom'

// action
import { registerUser } from '../../slices/thunk'

//redux
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap'

// import images
import profileImg from '../../assets/images/profile-img.png'
import logoImg from '../../assets/images/logo.svg'
import { toast } from 'react-toastify'

const Register = () => {
  //meta title
  document.title = 'Register | Signready'
  const dispatch = useDispatch<any>()
  const [loadingState, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  //form validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      name: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required('Please Enter Your Email'),
      name: Yup.string().required('Please Enter Your name'),
      password: Yup.string().required('Please Enter Your Password'),
    }),
    onSubmit: async (values: any) => {
      try {
        const response = await dispatch(registerUser({ values, setLoading }))
        const { payload } = response

        if (response.type === 'user/registerUser/fulfilled') {
          toast.success('Registered successfully')
          navigate('/login')
        } else {
          if (payload.error?.email) {
            toast.error(payload.error.email[0])
          } else if (payload.error.name) {
            toast.error(payload.error.name[0])
          } else if (payload.error.password) {
            toast.error(payload.error.password[0])
          }
        }
      } catch (error: any) {}
    },
  })

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        {loadingState === true ? (
          <div className="loader-box">
            <div className="loader"></div>
          </div>
        ) : (
          ''
        )}
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={8} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle-self">
                  <Row>
                    <Col className="col-7">
                      <div className="bg-layout-text p-4">
                        <h5 className="bg-layout-text"> Register</h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}>
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Name</Label>
                        <Input
                          name="name"
                          type="text"
                          placeholder="Enter name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ''}
                          invalid={
                            validation.touched.name && validation.errors.name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.name}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          autoComplete="off"
                          type="password"
                          placeholder="Enter password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ''}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4 d-grid">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit">
                          Register
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Signready{' '}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{' '}
                  <Link to="/login" className="fw-medium text-primary">
                    {' '}
                    Login
                  </Link>{' '}
                </p>
                <p>© {new Date().getFullYear()} Signready</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
